
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'

export default defineComponent({
  components: {
    TmButton,
    TmTooltip,
  },
  props: {
    editable: {
      type: Boolean,
    },
    draggable: {
      type: Boolean,
    },
    system: {
      type: Boolean,
    },
    systemTooltip: {
      type: String,
    },
    wide: {
      type: Boolean,
    },
    hideDelete: {
      type: Boolean,
    },
  },
  emits: ['edit', 'delete'],
})
