
import { defineComponent, ref } from 'vue'
import InfoCard from '@/components/shared/templates/InfoCard.vue'
import InfoCardRow from '@/components/shared/templates/InfoCardRow.vue'
import TmButton from '@/components/shared/TmButton.vue'
import StatusPanel from '@/components/shared/tmPanel/StatusPanel.vue'
import StatusAddModal from '@/components/modals/shared/StatusAddModal.vue'
import { colorPaletteOptions } from '@/definitions/_general/_data/colorPaletteOptions'
import type { ColorPaletteType } from '@/definitions/_general/_types/colorPalette'
import type { PropType } from 'vue'

export default defineComponent({
  components: {
    InfoCard,
    InfoCardRow,
    StatusPanel,
    TmButton,
    StatusAddModal,
  },
  props: {
    statuses: {
      type: Array,
      required: true,
    },
    itemName: {
      type: String,
    },
    statusName: {
      type: String,
      default: 'status',
    },
    statusNamePlenty: {
      type: String,
      default: 'statuses',
    },
    paletteItems: {
      type: Array as PropType<ColorPaletteType[]>,
      default: () => colorPaletteOptions,
    },
    paletteLabel: {
      type: String,
      default: 'Label color',
    },
    deleteStatusFunction: {
      type: Function,
    },
  },
  emits: ['update:statuses'],
  setup(props, context) {
    const newStatusModal = ref()
    const openNewStatusModal = () => {
      newStatusModal.value.openModal()
    }

    const deleteStatus = (item: any) => {
      context.emit('update:statuses', props.statuses.filter(v => v !== item))
    }

    const addStatus = (item: any) => {
      context.emit('update:statuses', [...props.statuses, { isDefault: false, ...item, relatedCount: 0 }])
    }

    const updateStatus = (item: any, newItem: any) => {
      context.emit('update:statuses', props.statuses.map((v) => v === item ? newItem : v))
    }

    return {
      newStatusModal,
      openNewStatusModal,
      deleteStatus,
      addStatus,
      updateStatus,
    }
  },
})
