import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, renderSlot as _renderSlot, withModifiers as _withModifiers, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ee541920"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tm-panel__icon" }
const _hoisted_2 = { class: "d-flex align-center body-text-semi-bold-14 neutral--text lh-20 flex-grow-1 ml-2" }
const _hoisted_3 = {
  key: 0,
  class: "tm-panel__buttons"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_tooltip = _resolveComponent("tm-tooltip")!
  const _component_tm_button = _resolveComponent("tm-button")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["tm-panel", {
      'tm-panel__editable': _ctx.editable,
      'tm-panel--wide': _ctx.wide,
    }]),
    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.editable && _ctx.$emit('edit')))
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.system)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_tm_icon, {
              name: "lock",
              size: "xLarge",
              class: "light--text"
            }),
            (_ctx.systemTooltip)
              ? (_openBlock(), _createBlock(_component_tm_tooltip, { key: 0 }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.systemTooltip), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ], 64))
        : _createCommentVNode("", true),
      (_ctx.draggable)
        ? (_openBlock(), _createBlock(_component_tm_icon, {
            key: 1,
            name: "drag_indicator",
            size: "xLarge",
            class: "light--text"
          }))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    (_ctx.editable)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_tm_button, {
            "icon-only": "",
            flat: "",
            color: "transparent",
            size: "large"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_tm_icon, {
                name: "edit",
                size: "xLarge",
                class: "neutral--text"
              })
            ]),
            _: 1
          }),
          (!_ctx.hideDelete)
            ? (_openBlock(), _createBlock(_component_tm_button, {
                key: 0,
                "icon-only": "",
                flat: "",
                color: "transparent",
                size: "large",
                onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('delete')), ["stop"]))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_tm_icon, {
                    name: "delete",
                    size: "xLarge",
                    class: "neutral--text"
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 2))
}