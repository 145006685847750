
import { defineComponent, ref, computed } from 'vue'
import { formatNumber } from '@/services/utils'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmColoredUl from '@/components/shared/templates/TmColoredUl.vue'

export default defineComponent({
  components: {
    TmModal,
    TmFormLine,
    TmButton,
    TmColoredUl,
  },
  props: {
    currentStatus: {
      type: Object,
      required: true,
    },
    itemName: {
      type: String,
    },
    tasksNumber: {
      type: Number,
    },
    statusName: {
      type: String,
      default: 'status',
    },
  },
  emits: ['onUpdate', 'onDelete'],
  setup(props, context) {
    const modal = ref()
    const openModal = () => {
      modal.value.openModal()
    }
    const chatsStatusOptions = ref([
      {
        name: 'open',
        color: 'orange',
        outline: true,
      },
      {
        name: 'solved',
        color: 'green',
        outline: true,
      },
      {
        name: 'pending',
        color: 'blue',
        outline: true,
      },
    ])
    const chatsStatus = ref(chatsStatusOptions.value[0])

    const onDelete = () => {
      context.emit('onDelete')
      modal.value.hideModal()
    }

    const totalCount = computed(() => {
      return props.tasksNumber ? props.tasksNumber : props.currentStatus.relatedCount
    })

    const newStatus = computed(() => {
      return {
        isDefault: props.currentStatus.isDefault,
        color: props.currentStatus.color,
        outline: props.currentStatus.outline,
        name: props.currentStatus.name,
        relatedCount: 0,
      }
    })
    const onUpdate = () => {
      context.emit('onUpdate', newStatus.value)
    }

    return {
      chatsStatus,
      chatsStatusOptions,
      formatNumber,
      modal,
      onDelete,
      onUpdate,
      totalCount,

      // methods for using from parents
      openModal,
    }
  },
})
