
import { defineComponent, ref, computed } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import TmColorPalette from '@/components/shared/TmColorPalette.vue'
import { Form as Validation } from 'vee-validate'
import { colorPaletteOptions } from '@/definitions/_general/_data/colorPaletteOptions'
import { capitalize } from '@/services/utils'
import type { ColorPaletteType } from '@/definitions/_general/_types/colorPalette'
import { capitalizeFirstLetter } from '@/services/string'
import type { PropType } from 'vue'

export default defineComponent({
  components: {
    TmModal,
    TmButton,
    TmFormLine,
    TmStatus,
    TmColorPalette,
    Validation,
  },
  props: {
    modalTitle: {
      type: String,
    },
    buttonText: {
      type: String,
      default: 'Create',
    },
    currentStatus: {
      type: Object,
    },
    statusName: {
      type: String,
      default: 'status',
    },
    itemName: {
      type: String,
      default: '',
    },
    paletteItems: {
      type: Array as PropType<ColorPaletteType[]>,
      default: () => colorPaletteOptions,
    },
    paletteLabel: {
      type: String,
      default: 'Label color',
    },
    hidePalette: {
      type: Boolean,
    },
  },
  emits: ['addStatus'],
  setup(props, context) {
    const statusText = ref(props.currentStatus ? capitalizeFirstLetter(props.currentStatus.name) : '')

    const modal = ref()
    const openModal = () => {
      modal.value.openModal()
    }

    const form = ref()
    const validateForm = () => {
      form.value.$el.dispatchEvent(new Event('submit', { cancelable: true }))
    }

    const selectedStatus = ref({
      color: props.currentStatus ? props.currentStatus.color : props.paletteItems[0].color,
      outline: props.currentStatus ? props.currentStatus.outline : props.paletteItems[0].outline,
    })

    const newStatus = computed(() => {
      return {
        name: statusText.value,
        ...selectedStatus.value,
      }
    })

    const onSubmit = () => {
      context.emit('addStatus', newStatus.value)
      modal.value.hideModal()
    }

    return {
      validateForm,
      modal,
      selectedStatus,
      statusText,
      newStatus,
      form,
      capitalize,
      onSubmit,

      // methods for using from parents
      openModal,
    }
  },
})
