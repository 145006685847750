import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex-grow-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_status = _resolveComponent("tm-status")!
  const _component_tm_color_indicator = _resolveComponent("tm-color-indicator")!
  const _component_tm_panel = _resolveComponent("tm-panel")!
  const _component_status_add_modal = _resolveComponent("status-add-modal")!
  const _component_status_delete_modal = _resolveComponent("status-delete-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_tm_panel, {
      editable: _ctx.editable,
      draggable: _ctx.draggable,
      system: !_ctx.editable,
      "hide-delete": _ctx.hideDelete,
      "system-tooltip": `You cannot edit a system ${_ctx.statusName}`,
      onEdit: _ctx.openStatusEditModal,
      onDelete: _ctx.openStatusDeleteModal
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default", {}, () => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_tm_status, {
              static: !_ctx.editable,
              name: _ctx.item.name,
              outline: _ctx.item.outline,
              color: _ctx.item.color
            }, null, 8, ["static", "name", "outline", "color"])
          ]),
          (_ctx.editable)
            ? (_openBlock(), _createBlock(_component_tm_color_indicator, {
                key: 0,
                hoverable: false,
                outline: _ctx.item.outline,
                color: _ctx.item.color
              }, null, 8, ["outline", "color"]))
            : _createCommentVNode("", true),
          (!_ctx.editable)
            ? (_openBlock(), _createBlock(_component_tm_status, {
                key: 1,
                name: "System",
                color: "gray",
                outline: true
              }))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 3
    }, 8, ["editable", "draggable", "system", "hide-delete", "system-tooltip", "onEdit", "onDelete"]),
    _createVNode(_component_status_add_modal, {
      ref: "statusEditModal",
      "modal-title": `Edit ${ _ctx.itemName.toLowerCase() } ${_ctx.statusName}`,
      "status-name": _ctx.statusName,
      "button-text": "Save",
      "hide-palette": _ctx.hidePalette,
      "current-status": _ctx.item,
      "palette-items": _ctx.paletteItems,
      onAddStatus: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('updateStatus', { ..._ctx.item, ...$event })))
    }, {
      preview: _withCtx(({ selectedStatus }) => [
        _renderSlot(_ctx.$slots, "preview", { selectedStatus: selectedStatus })
      ]),
      _: 3
    }, 8, ["modal-title", "status-name", "hide-palette", "current-status", "palette-items"]),
    _createVNode(_component_status_delete_modal, {
      ref: "statusDeleteModal",
      "item-name": _ctx.itemName,
      "status-name": _ctx.statusName,
      "current-status": _ctx.item,
      onOnDelete: _cache[1] || (_cache[1] = ($event: any) => (_ctx.deleteStatus(_ctx.item))),
      onOnUpdate: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('updateStatus', { ..._ctx.item, ...$event })))
    }, null, 8, ["item-name", "status-name", "current-status"])
  ], 64))
}