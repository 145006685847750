
import { defineComponent, ref } from 'vue'
import TmPanel from '@/components/shared/tmPanel/TmPanel.vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import TmColorIndicator from '@/components/shared/TmColorIndicator.vue'
import StatusAddModal from '@/components/modals/shared/StatusAddModal.vue'
import StatusDeleteModal from '@/components/modals/shared/StatusDeleteModal.vue'
import { colorPaletteOptions } from '@/definitions/_general/_data/colorPaletteOptions'
import type { ColorPaletteType } from '@/definitions/_general/_types/colorPalette'
import type { PropType } from 'vue'

export default defineComponent({
  components: {
    TmPanel,
    TmStatus,
    TmColorIndicator,
    StatusAddModal,
    StatusDeleteModal,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    system: {
      type: Boolean,
      default: false,
    },
    statuses: {
      type: Array,
      default: () => [],
    },
    itemName: {
      type: String,
      default: '',
    },
    statusName: {
      type: String,
      default: 'status',
    },
    paletteItems: {
      type: Array as PropType<ColorPaletteType[]>,
      default: () => colorPaletteOptions,
    },
    deleteStatusFunction: {
      type: Function,
    },
    draggable: {
      type: Boolean,
      default: true,
    },
    hideDelete: {
      type: Boolean,
    },
    hidePalette: {
      type: Boolean,
    },
  },
  emits: ['updateStatus', 'deleteStatus'],
  setup(props, context) {
    const nameEditVisible = ref(false)
    const deleteModal = ref()

    const statusEditModal = ref()
    const openStatusEditModal = () => {
      statusEditModal.value.openModal()
    }

    const statusDeleteModal = ref()
    const openStatusDeleteModal = () => {
      if (typeof props.deleteStatusFunction === 'function') {
        props.deleteStatusFunction()
      } else {
        statusDeleteModal.value.openModal()
      }
    }
    const deleteStatus = (item: any) => {
      context.emit('deleteStatus', item)
    }

    return {
      nameEditVisible,
      deleteModal,
      statusEditModal,
      openStatusEditModal,
      statusDeleteModal,
      openStatusDeleteModal,
      deleteStatus,
    }
  },
})
