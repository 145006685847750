import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "body-text-regular-14 lh-20 mb-4" }
const _hoisted_2 = { class: "mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_status_panel = _resolveComponent("status-panel")!
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_button = _resolveComponent("tm-button")!
  const _component_status_add_modal = _resolveComponent("status-add-modal")!
  const _component_info_card_row = _resolveComponent("info-card-row")!
  const _component_info_card = _resolveComponent("info-card")!

  return (_openBlock(), _createBlock(_component_info_card, {
    class: "statuses-list",
    title: `${_ctx.itemName} ${_ctx.statusNamePlenty}`
  }, {
    default: _withCtx(() => [
      _createVNode(_component_info_card_row, {
        title: `Specify your ${_ctx.itemName.toLowerCase()} ${_ctx.statusNamePlenty}`
      }, {
        text: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, " Add necessary " + _toDisplayString(_ctx.itemName.toLowerCase()) + " " + _toDisplayString(_ctx.statusNamePlenty) + " that are needed for your regular flow. ", 1),
          _createElementVNode("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.statuses, (item, i) => {
              return _renderSlot(_ctx.$slots, "default", {
                key: i,
                item: item
              }, () => [
                _createVNode(_component_status_panel, {
                  item: item,
                  editable: !item.isDefault,
                  system: item.system,
                  statuses: _ctx.statuses,
                  "item-name": _ctx.itemName,
                  "delete-status-function": _ctx.deleteStatusFunction,
                  "status-name": _ctx.statusName,
                  "palette-items": _ctx.paletteItems,
                  onUpdateStatus: ($event: any) => (_ctx.updateStatus(item, $event)),
                  onDeleteStatus: _cache[0] || (_cache[0] = ($event: any) => (_ctx.deleteStatus($event)))
                }, null, 8, ["item", "editable", "system", "statuses", "item-name", "delete-status-function", "status-name", "palette-items", "onUpdateStatus"])
              ])
            }), 128))
          ]),
          _createVNode(_component_tm_button, {
            "text-link": "",
            class: "primary--text",
            onClick: _ctx.openNewStatusModal
          }, {
            default: _withCtx(() => [
              _createVNode(_component_tm_icon, {
                name: "add",
                class: "mr-1"
              }),
              _createElementVNode("span", null, "Add new " + _toDisplayString(_ctx.statusName), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_status_add_modal, {
            ref: "newStatusModal",
            "modal-title": `Add new ${ _ctx.itemName.toLowerCase() } ${_ctx.statusName}`,
            "status-name": _ctx.statusName,
            "palette-label": _ctx.paletteLabel,
            "item-name": _ctx.itemName.toLowerCase(),
            "palette-items": _ctx.paletteItems,
            "button-text": "Add",
            onAddStatus: _ctx.addStatus
          }, {
            preview: _withCtx(({ selectedStatus }) => [
              _renderSlot(_ctx.$slots, "preview", { selectedStatus: selectedStatus })
            ]),
            _: 3
          }, 8, ["modal-title", "status-name", "palette-label", "item-name", "palette-items", "onAddStatus"])
        ]),
        _: 3
      }, 8, ["title"])
    ]),
    _: 3
  }, 8, ["title"]))
}